import React from 'react';
import { Header } from '../Header';
import { NetworkProposalContainer } from '../NetworkProposalContainer';

export function NetworkProposal() {
  return (
    <div className='Proposal-page'>
      <Header page='network' />
      <NetworkProposalContainer />
    </div>
  );
}
