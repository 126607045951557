import { createSlice } from '@reduxjs/toolkit';

export const templateSlice = createSlice({
  name: 'template',
  initialState: {
    value: 0,
    minParticipation: 66,
    minAgreement: 66,
    minDuration: 7,
  },
  reducers: {
    selectTemplate: (state, action) => {
      state.value = action.payload.idx;
      state.minParticipation = action.payload.minParticipation;
      state.minAgreement = action.payload.minAgreement;
      state.minDuration = action.payload.minDuration;
    },
    setMinParticipation: (state, action) => {
      state.minParticipation = action.payload;
    },
    setMinAgreement: (state, action) => {
      state.minAgreement = action.payload;
    }
  }
});

export const { selectTemplate, setMinParticipation, setMinAgreement } =
  templateSlice.actions;

export default templateSlice.reducer;
