import { configureStore } from '@reduxjs/toolkit';
import templateReducer from '../reducers/template';
import { ConnectWallet } from '../reducers/walletconnect.reducers';

export default configureStore({
  reducer: {
    template: templateReducer,
    ConnectWallet: ConnectWallet
  }
});
