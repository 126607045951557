import React from 'react';
import { Proposal } from './components/Proposal';
import { NetworkProposal } from './components/NetworkProposal';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <Router>
      <div className='App'>
        <Switch>
          <Route exact path='/'>
            <Proposal />
          </Route>
          <Route exact path='/network'>
            <NetworkProposal />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
