export const ProposalTemplateSC = {
  mainnetAddress: '0x7403b7d2d0645f576ef5f702736390ad9fb6f6b0',
  testnetAddress: '0xD14b356934397049128aa6C8650af6d89A076E0a',
  abi: [
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'pType',
          type: 'uint256'
        }
      ],
      name: 'AddedTemplate',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: false,
          internalType: 'uint256',
          name: 'pType',
          type: 'uint256'
        }
      ],
      name: 'ErasedTemplate',
      type: 'event'
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: 'address',
          name: 'previousOwner',
          type: 'address'
        },
        {
          indexed: true,
          internalType: 'address',
          name: 'newOwner',
          type: 'address'
        }
      ],
      name: 'OwnershipTransferred',
      type: 'event'
    },
    {
      constant: true,
      inputs: [],
      name: 'isOwner',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'owner',
      outputs: [{ internalType: 'address', name: '', type: 'address' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [],
      name: 'renounceOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
      name: 'transferOwnership',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [],
      name: 'version',
      outputs: [{ internalType: 'bytes4', name: '', type: 'bytes4' }],
      payable: false,
      stateMutability: 'pure',
      type: 'function'
    },
    {
      constant: false,
      inputs: [],
      name: 'initialize',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'pType', type: 'uint256' }],
      name: 'exists',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [{ internalType: 'uint256', name: 'pType', type: 'uint256' }],
      name: 'get',
      outputs: [
        { internalType: 'string', name: 'name', type: 'string' },
        { internalType: 'address', name: 'verifier', type: 'address' },
        {
          internalType: 'enum Proposal.ExecType',
          name: 'executable',
          type: 'uint8'
        },
        { internalType: 'uint256', name: 'minVotes', type: 'uint256' },
        { internalType: 'uint256', name: 'minAgreement', type: 'uint256' },
        { internalType: 'uint256[]', name: 'opinionScales', type: 'uint256[]' },
        { internalType: 'uint256', name: 'minVotingDuration', type: 'uint256' },
        { internalType: 'uint256', name: 'maxVotingDuration', type: 'uint256' },
        { internalType: 'uint256', name: 'minStartDelay', type: 'uint256' },
        { internalType: 'uint256', name: 'maxStartDelay', type: 'uint256' }
      ],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'uint256', name: 'pType', type: 'uint256' },
        { internalType: 'string', name: 'name', type: 'string' },
        { internalType: 'address', name: 'verifier', type: 'address' },
        {
          internalType: 'enum Proposal.ExecType',
          name: 'executable',
          type: 'uint8'
        },
        { internalType: 'uint256', name: 'minVotes', type: 'uint256' },
        { internalType: 'uint256', name: 'minAgreement', type: 'uint256' },
        { internalType: 'uint256[]', name: 'opinionScales', type: 'uint256[]' },
        { internalType: 'uint256', name: 'minVotingDuration', type: 'uint256' },
        { internalType: 'uint256', name: 'maxVotingDuration', type: 'uint256' },
        { internalType: 'uint256', name: 'minStartDelay', type: 'uint256' },
        { internalType: 'uint256', name: 'maxStartDelay', type: 'uint256' }
      ],
      name: 'addTemplate',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: false,
      inputs: [{ internalType: 'uint256', name: 'pType', type: 'uint256' }],
      name: 'eraseTemplate',
      outputs: [],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'function'
    },
    {
      constant: true,
      inputs: [
        { internalType: 'uint256', name: 'pType', type: 'uint256' },
        {
          internalType: 'enum Proposal.ExecType',
          name: 'executable',
          type: 'uint8'
        },
        { internalType: 'uint256', name: 'minVotes', type: 'uint256' },
        { internalType: 'uint256', name: 'minAgreement', type: 'uint256' },
        { internalType: 'uint256[]', name: 'opinionScales', type: 'uint256[]' },
        { internalType: 'uint256', name: 'start', type: 'uint256' },
        { internalType: 'uint256', name: 'minEnd', type: 'uint256' },
        { internalType: 'uint256', name: 'maxEnd', type: 'uint256' }
      ],
      name: 'verifyProposalParams',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [
        { internalType: 'uint256', name: 'pType', type: 'uint256' },
        { internalType: 'address', name: 'propAddr', type: 'address' }
      ],
      name: 'verifyProposalContract',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    }
  ]
};

export const PlainTextProposalSC = {
  mainnetAddress: '0xc9f5aa2c93ae9e89a77dc60e7e3cfff1234e4287',
  testnetAddress: '0xE8ebDb93710788cF173Bf694c70a3640cA34Dd4B',
  abi: [
    {
      inputs: [
        { internalType: 'address', name: '_govAddress', type: 'address' }
      ],
      payable: false,
      stateMutability: 'nonpayable',
      type: 'constructor'
    },
    {
      constant: true,
      inputs: [
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'address', name: 'propAddr', type: 'address' }
      ],
      name: 'verifyProposalContract',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: true,
      inputs: [
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'enum Proposal.ExecType', name: '', type: 'uint8' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'uint256[]', name: '', type: 'uint256[]' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'uint256', name: '', type: 'uint256' },
        { internalType: 'uint256', name: '', type: 'uint256' }
      ],
      name: 'verifyProposalParams',
      outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
      payable: false,
      stateMutability: 'view',
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { internalType: 'string', name: '__name', type: 'string' },
        { internalType: 'string', name: '__description', type: 'string' },
        { internalType: 'bytes32[]', name: '__options', type: 'bytes32[]' },
        { internalType: 'uint256', name: '__minVotes', type: 'uint256' },
        { internalType: 'uint256', name: '__minAgreement', type: 'uint256' },
        { internalType: 'uint256', name: '__start', type: 'uint256' },
        { internalType: 'uint256', name: '__minEnd', type: 'uint256' },
        { internalType: 'uint256', name: '__maxEnd', type: 'uint256' }
      ],
      name: 'create',
      outputs: [],
      payable: true,
      stateMutability: 'payable',
      type: 'function'
    }
  ]
};

export const NetworkParameterFactorySC = {
  mainnetAddress: '',
  testnetAddress: '0x09F44D7F15dd65529CF261c5ea8364e09A51d7C3',
  abi: [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_governance",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "_constsAddress",
          "type": "address"
        }
      ],
      "payable": false,
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "constant": false,
      "inputs": [
        {
          "internalType": "string",
          "name": "__description",
          "type": "string"
        },
        {
          "internalType": "uint8",
          "name": "__methodID",
          "type": "uint8"
        },
        {
          "internalType": "uint256[]",
          "name": "__optionVals",
          "type": "uint256[]"
        },
        {
          "internalType": "uint256",
          "name": "__minVotes",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "__minAgreement",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "__start",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "__minEnd",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "__maxEnd",
          "type": "uint256"
        }
      ],
      "name": "create",
      "outputs": [],
      "payable": true,
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "constant": true,
      "inputs": [],
      "name": "lastNetworkProposal",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    },
    {
      "constant": true,
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "propAddr",
          "type": "address"
        }
      ],
      "name": "verifyProposalContract",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    },
    {
      "constant": true,
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        },
        {
          "internalType": "enum Proposal.ExecType",
          "name": "",
          "type": "uint8"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        },
        {
          "internalType": "uint256[]",
          "name": "",
          "type": "uint256[]"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "verifyProposalParams",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }
  ]
};

const SFC = {
  mainnetAddress: '',
  testnetAddress: '0x62d5Eb7AFe345881970D269447e59e48BaB2b79C'
};

export const ChainIDs = {
  mainnet: 250,
  testnet: 4002
};

export const ProposalFee = {
  mainnet: '100.0',
  testnet: '1.0'
};

export const DestNet = {
  ChainID:
    process.env.REACT_APP_DESTNET && process.env.REACT_APP_DESTNET === 'mainnet'
      ? ChainIDs.mainnet
      : ChainIDs.testnet,
  ProposalFee:
    process.env.REACT_APP_DESTNET && process.env.REACT_APP_DESTNET === 'mainnet'
      ? ProposalFee.mainnet
      : ProposalFee.testnet,
  ProposalTemplateSC:
    process.env.REACT_APP_DESTNET && process.env.REACT_APP_DESTNET === 'mainnet'
      ? ProposalTemplateSC.mainnetAddress
      : ProposalTemplateSC.testnetAddress,
  PlainTextProposalSC:
    process.env.REACT_APP_DESTNET && process.env.REACT_APP_DESTNET === 'mainnet'
      ? PlainTextProposalSC.mainnetAddress
      : PlainTextProposalSC.testnetAddress,
  NetworkParameterFactorySC:
    process.env.REACT_APP_DESTNET && process.env.REACT_APP_DESTNET === 'mainnet'
      ? NetworkParameterFactorySC.mainnetAddress
      : NetworkParameterFactorySC.testnetAddress,
  SFC:
    process.env.REACT_APP_DESTNET && process.env.REACT_APP_DESTNET === 'mainnet'
      ? SFC.mainnetAddress
      : SFC.testnetAddress
};
