import React from 'react';
import AccountAvatar from './avatar.svg';
import AccountStatus from './status.svg';
import DownArrow from './down_arrow.svg';

export function Account(props) {
  const start_and_end = (str) => {
    if (str === undefined) {
      return '';
    }
    if (str.length > 10) {
      return str.substr(0, 5) + '...' + str.substr(str.length - 4, str.length);
    }
    return str;
  };

  return (
    <div className='Header-account'>
      <div className='Header-account-avatar-container'>
        <img
          src={AccountAvatar}
          className='Header-account-avatar'
          alt='avatar'
        />
        <img
          src={AccountStatus}
          className='Header-account-status'
          alt='status'
        />
      </div>
      <div className='Header-account-address-container'>
        <div className='Header-account-address'>
          <span>{start_and_end(props.address)}</span>
          <img
            src={DownArrow}
            className='Header-account-dropdown'
            alt='downarrow'
          />
        </div>
        <div className='Header-account-metamask'>Metamask</div>
      </div>
    </div>
  );
}
