import React from 'react';
import { Header } from '../Header';
import { ProposalContainer } from '../ProposalContainer';

export function Proposal() {
  return (
    <div className='Proposal-page'>
      <Header page='proposal' />
      <ProposalContainer />
    </div>
  );
}
