import React, { useState } from 'react';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ethers } from 'ethers';
import ClipLoader from 'react-spinners/ClipLoader';
import {
  DestNet,
  ProposalTemplateSC,
  PlainTextProposalSC
} from '../../constants/contract.constants';
import closeBtn from './close_btn.svg';
import proposalSuccess from './success.svg';
import proposalFailure from './failure.svg';
import 'rc-slider/assets/index.css';

export function ProposalContainer() {
  const SliderWithTooltip = createSliderWithTooltip(Slider);

  const minParticipationLimit = useSelector(
    (state) => state.template.minParticipation
  );
  const minAgreementLimit = useSelector((state) => state.template.minAgreement);
  const templateIdx = 1;

  let participationMarks = {
    100: `100%`
  };
  participationMarks[`${minParticipationLimit}`] = `${minParticipationLimit}%`;

  let agreementMarks = {
    100: `100%`
  };
  agreementMarks[`${minAgreementLimit}`] = `${minAgreementLimit}%`;

  const startTimeMinLimit = 0;
  const startTimeMaxLimit = templateIdx === 1 ? 10 : 24;
  const minimumTimeMinLimit = 500;
  const minimumTimeMaxLimit = templateIdx === 1 ? 500 : 30;
  const maximumTimeMaxLimit = 1000;

  const [modalIsOpen, setIsOpen] = useState(false);
  const [voteOptions, setVoteOptions] = useState(['']);
  const [proposalSucceeded, setProposalSuccedded] = useState(0);
  const [minimumParticipation, setMinimumParticipation] = useState(
    minParticipationLimit
  );
  const [minimumAgreement, setMinimumAgreement] = useState(minAgreementLimit);
  const [minimumStartTime, setMinimumStartTime] = useState(startTimeMinLimit);
  const [minimumDuration, setMinimumDuration] = useState(minimumTimeMinLimit);
  const maximumTimeMinLimit = templateIdx === 1 ? minimumDuration : 14;
  const [maximumDuration, setMaximumDuration] = useState(maximumTimeMinLimit);
  const [proposalTitle, setProposalTitle] = useState('');
  const [proposalDescription, setProposalDescription] = useState('');
  const [isCreatingProposal, setCreatingProposal] = useState(false);

  let isConnected = useSelector((state) => state.ConnectWallet.isConnected);
  let chainId = useSelector((state) => state.ConnectWallet.chainId);

  const { register, formState, handleSubmit, getValues, reset } = useForm({
    mode: 'onChange'
  });
  const errors = formState.errors;

  const checkOptionValidity = () => {
    const optionNames = Object.keys(voteOptions).map(
      (idx) => `voteOptions${idx}`
    );
    const optionValues = getValues(optionNames);
    const result = optionValues.findIndex((value) => value !== '');
    return result >= 0;
  };

  const optionErrors = Object.keys(voteOptions).map((idx) =>
    `voteOptions${idx}` in errors ? 1 : 0
  );
  const errorExist = optionErrors.findIndex((value) => value === 1);
  const optionInvalid = !checkOptionValidity() && errorExist >= 0;
  const endingTimeValid =
    parseInt(maximumDuration) >= parseInt(minimumDuration);

  // Duration errors
  let durationErrors = [];
  if (errors.start_time) {
    durationErrors = [...durationErrors, errors.start_time.message];
  }
  if (errors.ending_minimum) {
    durationErrors = [...durationErrors, errors.ending_minimum.message];
  }
  if (errors.ending_maximum) {
    durationErrors = [...durationErrors, errors.ending_maximum.message];
  }
  if (!endingTimeValid) {
    durationErrors = [
      ...durationErrors,
      'Minimum time should be equal or less than maximum time'
    ];
  }

  for (let i = durationErrors.length; i < 4; i++) {
    durationErrors = [...durationErrors, ''];
  }

  const createProposal = async () => {
    if (!isConnected) {
      // handle when not connected
      setProposalSuccedded(-1);
      openModal();
      setCreatingProposal(false);
    } else {
      if (chainId !== DestNet.ChainID) {
        setProposalSuccedded(-1);
        openModal();
        setCreatingProposal(false);
        return;
      }
      let templateSC = await loadContract(
        DestNet.ProposalTemplateSC,
        ProposalTemplateSC.abi
      );
      let plainTextProposalFactorySC = await loadContract(
        DestNet.PlainTextProposalSC,
        PlainTextProposalSC.abi
      );

      try {
        let result = await handlePlainTextProposal(
          templateSC,
          plainTextProposalFactorySC
        );
        if (result) {
          setProposalSuccedded(1);
          openModal();
          resetInputFields();
          setCreatingProposal(false);
        }
      } catch (error) {
        console.log('There has been an error in creating a proposal');
        setProposalSuccedded(0);
        openModal();
        setCreatingProposal(false);
      }
    }
  };

  const loadContract = async (address, abi) => {
    if (window.ethereum === undefined) {
      return;
    }
    await window.ethereum.enable();
    let provider = new ethers.providers.Web3Provider(window.ethereum);
    let signer = provider.getSigner();
    const assuredContract = new ethers.Contract(address, abi, provider);
    return assuredContract.connect(signer);
  };

  const handlePlainTextProposal = async (
    templateSC,
    plainTextProposalFactorySC
  ) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const accounts = await provider.listAccounts();
    const account = accounts[0];
    const proposalName = getValues('name');
    const proposalDescription = getValues('description');
    const startTime = getValues('start_time');
    const minEndTime = getValues('ending_minimum');
    const maxEndTime = getValues('ending_maximum');
    let options = [];

    voteOptions.forEach((val) => {
      if (val.length > 0) {
        let _val = val.trim();
        if (_val.length > 31) {
          _val = _val.slice(0, 31);
        }
        options.push(ethers.utils.formatBytes32String(_val));
      }
    });

    let minVoteAmount = ethers.utils.parseUnits(
      minimumParticipation.toFixed(1),
      18
    );
    minVoteAmount = minVoteAmount.div(ethers.BigNumber.from(100));
    let minAgreeAmount = ethers.utils.parseUnits(
      minimumAgreement.toFixed(1),
      18
    );
    minAgreeAmount = minAgreeAmount.div(ethers.BigNumber.from(100));

    let result = await (
      await plainTextProposalFactorySC.create(
        proposalName,
        proposalDescription,
        options,
        minVoteAmount,
        minAgreeAmount,
        parseInt(startTime, 10),
        parseInt(minEndTime, 10),
        parseInt(maxEndTime, 10),
        { from: account, value: ethers.utils.parseEther('100.0') }
      )
    ).wait();
    return result;
  };
  const onSubmit = () => {
    setCreatingProposal(true);
    createProposal();
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const addOption = (e) => {
    let added = [...voteOptions, ''];
    setVoteOptions(added);
    e.preventDefault();
    return false;
  };

  const resetInputFields = () => {
    setProposalTitle('');
    setProposalDescription('');
    setVoteOptions(['']);
    setMinimumParticipation(minParticipationLimit);
    setMinimumAgreement(minAgreementLimit);
    setMinimumStartTime(startTimeMinLimit);
    setMinimumDuration(minimumTimeMinLimit);
    setMaximumDuration(minimumTimeMinLimit);
    reset(
      {
        name: '',
        description: '',
        voteOptions: [],
        start_time: startTimeMinLimit,
        ending_minimum: minimumTimeMinLimit,
        ending_maximum: minimumTimeMinLimit
      },
      {
        keepErrors: true,
        keepDirty: true,
        keepIsSubmitted: false,
        keepTouched: false,
        keepIsValid: false,
        keepSubmitCount: false
      }
    );
  };

  const setVoteOption = (idx, value) => {
    let newOptions = [...voteOptions];
    newOptions[idx] = value;
    setVoteOptions(newOptions);
  };

  const filterReturnKey = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  };

  const handleMinimumDuration = (val) => {
    setMinimumDuration(val);
    if (parseInt(val) > parseInt(maximumDuration)) {
      setMaximumDuration(val);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='Proposal-container'>
        <h2>Create on-chain governance proposals</h2>
        <div className='Proposal-description-container'>
          <div className='Proposal-description-row'>
            <label>Name of the proposal</label>
            <input
              name='name'
              placeholder='Enter a name for your proposal'
              value={proposalTitle}
              onKeyDown={filterReturnKey}
              onInput={(e) => {
                if (e.target.value.length <= 120) {
                  setProposalTitle(e.target.value);
                }
              }}
              {...register('name', {
                required: 'Please enter a title'
              })}
            />
            <div className='Proposal-input-footer'>
              {errors.name ? <p>{errors.name.message}</p> : <p>&nbsp;</p>}
              <span>{proposalTitle.length}/120</span>
            </div>
          </div>
          <div className='Proposal-description-row'>
            <label>Description</label>
            <textarea
              name='description'
              placeholder='Enter a description for your proposal'
              value={proposalDescription}
              onInput={(e) => {
                if (e.target.value.length <= 500) {
                  setProposalDescription(e.target.value);
                }
              }}
              {...register('description', {
                required: 'Please enter a description'
              })}></textarea>
            <div className='Proposal-input-footer'>
              {errors.description ? (
                <p>{errors.description.message}</p>
              ) : (
                <p>&nbsp;</p>
              )}
              <span>{proposalDescription.length}/500</span>
            </div>
          </div>
          <div className='Proposal-description-row'>
            <label>Voting options</label>
            {voteOptions.map((option, idx) => {
              return (
                <div key={idx} className='Proposal-option-container'>
                  <input
                    name='voteOptions'
                    placeholder='Input an option'
                    value={option}
                    onKeyDown={filterReturnKey}
                    onInput={(e) => {
                      if (e.target.value.length <= 31) {
                        setVoteOption(idx, e.target.value);
                      }
                    }}
                    {...register(`voteOptions${idx}`, {
                      validate: checkOptionValidity
                    })}
                  />
                  <div className='Proposal-input-footer'>
                    {optionInvalid && idx === voteOptions.length - 1 ? (
                      <p>Please enter at least one voting option</p>
                    ) : (
                      <p>&nbsp;</p>
                    )}
                    <span>{option.length}/31</span>
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <button
              className='Proposal-vote-option-add-btn'
              type='button'
              onClick={addOption}>
              Add one more option
            </button>
          </div>
        </div>
        <div className='Proposal-threshold-container'>
          <div className='Proposal-threshold-row'>
            <label>Minimum participation</label>
            <SliderWithTooltip
              tipProps={{
                prefixCls: 'rc-slider-tooltip',
                placement: 'bottom',
                visible: true
              }}
              tipFormatter={(value) => `${value}%`}
              min={minParticipationLimit}
              max={100}
              marks={participationMarks}
              dots={false}
              defaultValue={minimumParticipation}
              onAfterChange={(val) => setMinimumParticipation(val)}
            />
          </div>
          <div className='Proposal-threshold-row'>
            <label>Minimum agreement</label>
            <SliderWithTooltip
              tipProps={{
                prefixCls: 'rc-slider-tooltip',
                placement: 'bottom',
                visible: true
              }}
              tipFormatter={(value) => `${value}%`}
              min={minAgreementLimit}
              max={100}
              marks={agreementMarks}
              dots={false}
              defaultValue={minimumAgreement}
              onAfterChange={(val) => setMinimumAgreement(val)}
            />
          </div>
          <div className='Proposal-period-threshold-container'>
            <div className='Proposal-period-container'>
              <label>Start time</label>
              <div className='Proposal-time-selector'>
                <input
                  type='number'
                  name='start_time'
                  placeholder='1'
                  value={minimumStartTime}
                  onKeyDown={filterReturnKey}
                  onInput={(e) => setMinimumStartTime(e.target.value)}
                  {...register('start_time', {
                    required: 'Please enter start time',
                    min: {
                      value: startTimeMinLimit,
                      message: `Please enter start time between ${startTimeMinLimit} and ${startTimeMaxLimit}`
                    },
                    max: {
                      value: startTimeMaxLimit,
                      message: `Please enter start time between ${startTimeMinLimit} and ${startTimeMaxLimit}`
                    }
                  })}
                />
                <div>hours</div>
              </div>
              <label className='Proposal-time-range'>
                min {startTimeMinLimit} - max {startTimeMaxLimit}
              </label>
            </div>
            <div className='Proposal-period-container'>
              <label>Ending in minimum</label>
              <div className='Proposal-time-selector'>
                <input
                  type='number'
                  name='ending_minimum'
                  placeholder={minimumTimeMinLimit}
                  value={minimumDuration}
                  onKeyDown={filterReturnKey}
                  onInput={(e) => handleMinimumDuration(e.target.value)}
                  {...register('ending_minimum', {
                    required: 'Please enter minimum time',
                    min: {
                      value: minimumTimeMinLimit,
                      message: `Please enter minimum time between ${minimumTimeMinLimit} and ${minimumTimeMaxLimit}`
                    },
                    max: {
                      value: minimumTimeMaxLimit,
                      message: `Please enter minimum time between ${minimumTimeMinLimit} and ${minimumTimeMaxLimit}`
                    }
                  })}
                />
                <div>days</div>
              </div>
              <label className='Proposal-time-range'>
                min {minimumTimeMinLimit} - max {minimumTimeMaxLimit}
              </label>
            </div>
            <div className='Proposal-period-container'>
              <label>Ending in maximum</label>
              <div className='Proposal-time-selector'>
                <input
                  type='number'
                  name='ending_maximum'
                  placeholder={maximumTimeMinLimit}
                  value={maximumDuration}
                  onKeyDown={filterReturnKey}
                  onInput={(e) => setMaximumDuration(e.target.value)}
                  {...register('ending_maximum', {
                    required: 'Please enter maximum time',
                    min: {
                      value: maximumTimeMinLimit,
                      message: `Please enter maximum time between ${maximumTimeMinLimit} and ${maximumTimeMaxLimit}`
                    },
                    max: {
                      value: maximumTimeMaxLimit,
                      message: `Please enter maximum time between ${maximumTimeMinLimit} and ${maximumTimeMaxLimit}`
                    }
                  })}
                />
                <div>days</div>
              </div>
              <label className='Proposal-time-range'>
                min {maximumTimeMinLimit} - max {maximumTimeMaxLimit}
              </label>
            </div>
          </div>
          {durationErrors.map((message, idx) => {
            return message !== '' ? (
              <div key={idx} className='Proposal-input-footer'>
                <p>{message}</p>
              </div>
            ) : null;
          })}
          <div className='Proposal-threshold-row'>
            <button
              className='Proposal-create-btn'
              // disabled={
              //   !formState.isValid || !endingTimeValid || isCreatingProposal
              // }
              >
              <ClipLoader
                color='#EFF3FB'
                loading={isCreatingProposal}
                size={24}
              />
              {!isCreatingProposal && 'Create proposal'}
            </button>
            <label className='Proposal-fee'>Proposal fee: 100 FTM</label>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className='Modal'
        overlayClassName='Overlay'
        contentLabel='Example Modal'
        ariaHideApp={false}>
        <img
          src={closeBtn}
          className='Modal-close-btn'
          onClick={closeModal}
          alt='close'
        />
        <img
          src={proposalSucceeded > 0 ? proposalSuccess : proposalFailure}
          className='Modal-proposal-success'
          alt='success'
        />
        {proposalSucceeded === 1 ? (
          <p>Proposal successfully created!</p>
        ) : proposalSucceeded === 0 ? (
          <p>The proposal couldn't be created, try again!</p>
        ) : (
          <p>
            Please connect to the{' '}
            {process.env.REACT_APP_DESTNET
              ? process.env.REACT_APP_DESTNET
              : 'testnet'}{' '}
            first!
          </p>
        )}
      </Modal>
    </form>
  );
}
